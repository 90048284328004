<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <template
            v-if="$permissionAbility(USERS_FAMILY_INFO_CREATE, permissions)"
          >
            <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Add
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        mode="remote"
        styleClass="vgt-table family-contact-table  condensed"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [
            { field: 'id', type: 'desc' },
            { field: 'name', type: 'desc' },
            { field: 'created_at', type: 'desc' },
          ],
        }"
        :columns="columns"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">

             <template v-if="$permissionAbility(USERS_FAMILY_INFO_EDIT, permissions)">
              <span @click="onShow(props.row)">
                <feather-icon
                    v-b-tooltip.hover
                    icon="Edit2Icon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Edit"
                    variant="warning"
                    size="16"
                />
              </span>
            </template>

            <template
                v-if="$permissionAbility(USERS_FAMILY_INFO_DELETE, permissions)"
            >
              <span @click="onDelete(props.row)">

                <feather-icon
                    v-b-tooltip.hover
                    icon="TrashIcon"
                    class="mr-50 custom-icon cursor-pointer"
                    variant="danger"
                    title="Delete"
                    size="16"
                />
              </span>
            </template>

          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-user-family-contact-form"
      centered
      :title="
        modelType == 'editModel'
          ? 'Edit Contact Person Info'
          : 'Add Contact Person Info'
      "
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >
      <validation-observer ref="userFamilyContactValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- Person Name -->
          <b-form-group label=" Name *" label-for="name">
            <ValidationProvider
              name="name"
              v-slot="{ errors }"
              vid="name"
              rules="required"
            >
              <b-form-input
                id="name"
                type="text"
                v-model="name"
                placeholder="Enter Person Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
          <!-- Relation -->
          <b-form-group label="Relation *" label-for="relation">
            <ValidationProvider
              name="relation"
              v-slot="{ errors }"
              vid="relation"
              rules="required"
            >
              <b-form-input
                id="relation"
                type="text"
                v-model="relation"
                placeholder="Enter Relationship With Employee"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
          <!-- Profession  -->
          <b-form-group label="Profession *" label-for="profession">
            <ValidationProvider
              name="profession"
              v-slot="{ errors }"
              vid="profession"
              rules="required"
            >
              <b-form-input
                id="profession"
                type="text"
                v-model="profession"
                placeholder="Enter Profession"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- Contact Number -->
          <b-form-group label="Contact Number *" label-for="contact">
            <ValidationProvider
              name="contact"
              v-slot="{ errors }"
              vid="contact"
              rules="required"
            >
              <b-form-input
                id="contact"
                v-model="contact"
                placeholder="Enter Contact Number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isUserFamilyContactFormSubmit">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormFile,
  BFormTextarea,
  BLink,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  USERS_FAMILY_INFO_CREATE,
  USERS_FAMILY_INFO_EDIT,
  USERS_FAMILY_INFO_DELETE,
} from "@/helpers/permissionsConstant";

export default {
  name: "DepartmentView",
  components: {
    BForm,
    BButton,
    BCard,
    BLink,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormFile,
    BFormTextarea,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      USERS_FAMILY_INFO_CREATE,
      USERS_FAMILY_INFO_EDIT,
      USERS_FAMILY_INFO_DELETE,
      modelType: "",
      familyContactId: "",
      name: "",
      profession: "",
      relation: "",
      contact: "",

      pageLength: 10,
      columns: [
        {
          label: "Person Name",
          field: "name",
          sortable: false,
        },

        {
          label: "Relationship",
          field: "relation",
          sortable: false,
        },
        {
          label: "Contact Number",
          field: "contact",
          sortable: false,
        },
        {
          label: "Profession",
          field: "profession",
          sortable: false,
        },

        {
          label: "Created On",
          field: "created_at",
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isUserFamilyContactFormSubmit: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    showModal() {
      this.$bvModal.show("modal-user-family-contact-form");
    },
    hiddenModal() {
      this.$bvModal.hide("modal-user-family-contact-form");
      this.resetModal();
    },
    resetModal() {
      this.modelType = "";
      this.familyContactId = "";
      this.name = "";
      this.profession = "";
      this.relation = "";
      this.contact = "";
    },

    async onShow(value) {
      this.modelType = "editModel";

      this.familyContactId = value.id;
      this.name = value.name;
      this.relation = value.relation;
      this.profession = value.profession;
      this.contact = value.contact;

      this.showModal();
    },
    async onDelete(row) {
      const id = row.id
      this.$swal({
        title: "Warning!",
        text: `Are You Sure You Want To Delete ${row.name}?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const formData = new FormData();

            formData.append("_method", "delete");

            if (this.$route.params.id) {
              formData.append("user_id", this.$route.params.id);
            }
            await this.$api.post(`/api/user-family-contact/${id}`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    async loadItems() {
      try {
        const userFamilyContacts = await this.$api.get(
          `api/user-family-contact/user/${this.$route.params.id}`
        );
        const data = userFamilyContacts?.data?.data;

        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning ",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.userFamilyContactValidation
        .validate()
        .then(async (success) => {
          if (success) {
            try {
              if (this.modelType == "editModel") {
                this.isUserFamilyContactFormSubmit = true;
                await this.$api.put(
                  `/api/user-family-contact/${this.familyContactId}`,
                  {
                    user_id: this.$route.params.id,
                    name: this.name,
                    profession: this.profession,
                    relation: this.relation,
                    contact: this.contact,
                  }
                );
                this.isUserFamilyContactFormSubmit = false;
                this.hiddenModal();

                this.loadItems();
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Successfully Updated",
                  },
                });
              } else {
                this.isUserFamilyContactFormSubmit = true;
                await this.$api.post(`/api/user-family-contact`, {
                  user_id: this.$route.params.id,
                  name: this.name,
                  profession: this.profession,
                  relation: this.relation,
                  contact: this.contact,
                });
                this.isUserFamilyContactFormSubmit = false;
                this.hiddenModal();

                this.loadItems();

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Successfully Created",
                  },
                });
              }
            } catch (error) {
              this.isUserFamilyContactFormSubmit = false;

              if (error?.response?.data?.message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error?.response?.data?.message,
                  },
                });
              }

              if (error?.response?.data?.errors) {
                this.$refs.userFamilyContactValidation.setErrors(
                  error?.response?.data?.errors
                );
              }
            }
          }
        });
    },
  },
};
</script>

<style lang="scss">
.family-contact-table {
  // font-size: 13px !important;
  // font-weight: 600 !important;
  min-height: 137px !important;
  white-space: nowrap !important;
  font-size: 13px !important;
}

@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
